@import url(https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav.responsive-toolbar {
  background: #333333;
  height: 60px;
  width: 100%;
  position: fixed;
  box-shadow: 2px 0px 2px #ccc; }
  nav.responsive-toolbar ~ * {
    padding-top: 60px; }
  nav.responsive-toolbar ul {
    background: #333333;
    display: flex;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    height: 100%;
    align-items: center;
    box-shadow: 2px 2px 2px #ccc; }
    nav.responsive-toolbar ul figure {
      -webkit-margin-before: 0;
              margin-block-start: 0;
      -webkit-margin-after: 0;
              margin-block-end: 0;
      -webkit-margin-start: 10px;
              margin-inline-start: 10px;
      -webkit-margin-end: 0;
              margin-inline-end: 0;
      cursor: pointer; }
    nav.responsive-toolbar ul li {
      list-style-type: none;
      padding: 10px 20px; }
      nav.responsive-toolbar ul li .active {
        background: #999; }
    nav.responsive-toolbar ul a {
      color: white;
      text-decoration: none;
      display: flex;
      flex-direction: row-reverse;
      align-items: center; }
    nav.responsive-toolbar ul i {
      margin-right: 5px;
      font-size: 20px; }

@media screen and (max-width: 759px) {
  nav.responsive-toolbar ul {
    height: 100%;
    width: 300px;
    left: -240px;
    position: fixed;
    top: 60px;
    flex-direction: column;
    transition: 300ms ease all; }
    nav.responsive-toolbar ul.active {
      left: 0px; }
    nav.responsive-toolbar ul figure {
      -webkit-margin-start: 2px;
              margin-inline-start: 2px;
      position: fixed;
      left: 9px;
      top: 10px; }
    nav.responsive-toolbar ul li {
      width: 100%;
      padding-left: 0;
      padding-right: 0; }
    nav.responsive-toolbar ul a {
      flex-direction: row;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 16px; } }

figure {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 10px;
          margin-inline-start: 10px;
  -webkit-margin-end: 0;
          margin-inline-end: 0; }

body {
  margin: 0; }

.main-header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  height: 50vh;
  background-color: #0a0908;
  color: #f2f4f3;
  line-height: 1; }
  .main-header__wrapper {
    max-width: 70em;
    margin: auto; }
  .main-header__text {
    display: flex;
    flex-direction: row;
    padding: 3rem 5rem; }
    .main-header__text-wrapper {
      width: 50%; }
    .main-header__text .header-title {
      font-size: 15px;
      text-align: left; }
    .main-header__text .header-subtitle {
      text-align: right; }
    .main-header__text .text-highlighted {
      font-weight: 600;
      font-size: 20px; }
  .main-header__logo {
    width: 100%;
    height: 25vh;
    position: absolute;
    top: 40%;
    transform: translateY(-40%);
    left: 0; }
    .main-header__logo-url {
      display: contents; }
    .main-header__logo .logo {
      width: 25vh;
      margin: auto;
      display: block; }
  .main-header__navigation {
    width: 60%;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center; }
  .main-header__navigation-item {
    width: 10%;
    padding: 0 16px;
    text-align: center;
    color: #f2f4f3; }
    .main-header__navigation-item:hover, .main-header__navigation-item:focus {
      color: #bbb; }
    .main-header__navigation-item:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%; }
  .main-header__toggle-button {
    display: none; }
  @media screen and (max-width: 768px) {
    .main-header__text {
      padding: 3rem 3rem; }
    .main-header__navigation-item {
      width: 10%;
      padding: 0 10px;
      font-size: 13px; } }
  @media screen and (max-width: 550px) {
    .main-header__text {
      padding: 2rem 1.5rem; }
    .main-header__text {
      font-size: 15px; }
    .main-header .header-title {
      font-size: 13px; }
    .main-header .text-highlighted {
      font-size: 15px; }
    .main-header__logo {
      width: 100%;
      height: 15vh;
      top: 42%;
      transform: translateY(-40%); }
      .main-header__logo .logo {
        width: 160px; }
    .main-header__navigation {
      width: 100%; } }

.link-cta {
  margin-top: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center; }
  .link-cta .link-text {
    color: #6f1a07;
    letter-spacing: 0px;
    line-height: 20px;
    font-size: 18px;
    font-weight: 900; }
    .link-cta .link-text:hover, .link-cta .link-text:active, .link-cta .link-text:focus {
      color: #5e503f; }
  @media screen and (max-width: 550px) {
    .link-cta {
      margin-top: 10px; }
      .link-cta .link-text {
        font-size: 15px; } }

.teaser {
  position: relative; }
  .teaser__in-grid {
    max-width: 70rem;
    margin: auto;
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center; }
  .teaser__item {
    padding: 60px 60px 0; }
  .teaser__image {
    display: flex;
    width: 100%; }
  .teaser .teaser-content__headline {
    color: #6f1a07;
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 0px;
    line-height: 50px;
    text-align: left; }
  .teaser .teaser-content__text {
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px; }
  .teaser-center {
    width: calc(100vw - 9px);
    height: 70vh;
    margin-left: -50vw;
    margin-top: -5rem;
    left: 50%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url(/assets/images/restaurant/asiatisches-restaurant-berlin.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
    position: relative;
    display: block;
    text-align: center;
    letter-spacing: 0px;
    color: #f2f4f3; }
    .teaser-center-content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-width: 50rem;
      left: 0;
      right: 0;
      margin: auto; }
      .teaser-center-content__headline {
        font-weight: 700;
        line-height: 70px;
        margin: 40px 0;
        font-size: 55px; }
      .teaser-center-content__text {
        font-size: 18px;
        line-height: 24px;
        text-align: center; }
  @media screen and (max-width: 550px) {
    .teaser__in-grid {
      grid-template-columns: 1fr;
      padding: 20px 0;
      text-align: center; }
    .teaser__item {
      padding: 0 20px; }
    .teaser .teaser-content__headline {
      font-size: 25px;
      text-align: center; }
    .teaser .teaser-content__text {
      font-size: 13px;
      line-height: 40px; }
    .teaser-center {
      width: 100vw;
      height: 60vh; }
      .teaser-center-content__headline {
        line-height: 40px;
        margin: 20px 0;
        font-size: 25px; }
      .teaser-center-content__text {
        font-size: 15px; } }

.menu__section {
  margin-bottom: 60px; }
  .menu__section:last-child {
    margin-bottom: 0; }
  .menu__section-name {
    width: 100%;
    background-color: #5e503f;
    text-align: center;
    color: #f2f4f3;
    line-height: 1.5; }
  .menu__section-image {
    width: 100%;
    height: 15rem;
    object-fit: cover; }
  .menu__section-note {
    width: 70%;
    margin: 15px auto 30px;
    font-style: italic;
    text-align: center; }
  .menu__section-list {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr; }
    .menu__section-list__item {
      margin-bottom: 10px; }
      .menu__section-list__item:nth-child(odd) {
        margin-right: 40px; }
      .menu__section-list__item:nth-child(even) {
        margin-left: 40px; }
      .menu__section-list__item .item-name {
        display: block;
        width: 80%;
        float: left; }
      .menu__section-list__item .item-title {
        color: #6f1a07; }
      .menu__section-list__item .item-info {
        font-size: 13px; }

@media screen and (max-width: 550px) {
  .menu__section-note {
    width: 90%; }
  .menu__section-list {
    grid-template-columns: 1fr; }
    .menu__section-list__item {
      margin-bottom: 10px; }
      .menu__section-list__item:nth-child(odd) {
        margin-right: 0; }
      .menu__section-list__item:nth-child(even) {
        margin-left: 0; } }

.order {
  position: relative; }
  .order-list {
    display: grid;
    flex-direction: column;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr; }
    .order-list__option {
      border: #5e503f 3px solid;
      border-radius: 5px;
      max-width: 25rem;
      background-color: #d1baa7;
      margin: 0 10px 20px;
      padding: 20px 30px 30px;
      text-align: center; }
      .order-list__option .option-title {
        display: block;
        margin: 0 auto 10px; }
      .order-list__option .option-subtitle {
        margin-bottom: 20px; }
      .order-list__option .option-logo {
        max-width: 200px;
        width: 100%;
        display: block;
        margin: auto; }
      .order-list__option .option-text {
        margin: 20px 0; }
      .order-list__option .option-button {
        max-width: 200px;
        background-color: #5e503f;
        border-radius: 5px;
        margin: auto; }
        .order-list__option .option-button:hover, .order-list__option .option-button:active, .order-list__option .option-button:focus {
          background-color: #785b38; }
        .order-list__option .option-button-wrapper {
          display: block;
          padding: 10px;
          color: #f2f4f3;
          font-weight: bold; }
  @media screen and (max-width: 550px) {
    .order-list {
      grid-template-columns: 1fr; }
      .order-list__option {
        padding: 20px 20px 30px;
        text-align: center; } }

.gallery-list {
  display: grid;
  flex-direction: column;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr; }
  .gallery-list__item {
    position: relative;
    max-width: 250px;
    max-height: 250px;
    margin: 10px;
    overflow: hidden; }
    .gallery-list__item-image {
      width: 100%;
      height: 100%; }
    .gallery-list__item .image-enlarged {
      background: rgba(0, 0, 0, 0.5) no-repeat center;
      width: 100%;
      height: 100%;
      position: fixed;
      z-Index: 10000;
      top: 0;
      left: 0; }

@media screen and (max-width: 768px) {
  .gallery-list {
    grid-template-columns: 1fr 1fr 1fr; } }

@media screen and (max-width: 550px) {
  .gallery-list {
    grid-template-columns: 1fr; }
    .gallery-list__item {
      margin: 10px 0; } }

.contact {
  max-width: 600px;
  margin: auto; }
  .contact__item {
    line-height: 2; }
  .contact .item-key {
    float: left;
    display: block;
    font-weight: bold;
    min-width: 200px; }
  @media screen and (max-width: 550px) {
    .contact__item-wrapper {
      margin-bottom: 10px; }
    .contact__item {
      line-height: 1.5; }
    .contact .item-key {
      float: none; } }

.policy__section {
  margin-bottom: 40px; }
  .policy__section-name {
    margin-bottom: 20px;
    font-size: 20px;
    color: #6f1a07; }
  .policy__section-para {
    margin-bottom: 20px;
    font-size: 13px; }

.footer {
  background-color: #0a0908;
  color: #f2f4f3; }
  .footer__wrapper {
    padding: 32px;
    max-width: 50rem;
    margin: auto; }
  .footer__items {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr; }
  .footer__column:last-child {
    text-align: right; }
  .footer__column-name {
    font-weight: 800; }
  .footer__logo {
    width: 100px;
    margin-bottom: 16px; }
  .footer__text {
    color: #f2f4f3; }
  .footer__information {
    margin-top: 16px;
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr; }
  .footer__data {
    font-weight: 800;
    color: #f2f4f3; }
    .footer__data:hover, .footer__data:focus {
      color: #bbb; }
  .footer__media {
    width: 25px;
    margin: 0 0 0 20px; }
  @media screen and (max-width: 550px) {
    .footer {
      font-size: 15px; }
      .footer__items {
        display: block; }
      .footer__column, .footer__column:last-child {
        text-align: center;
        margin-bottom: 20px; }
      .footer__information {
        grid-template-columns: 1fr; }
      .footer__media {
        width: 25px;
        margin: 0 10px; } }

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0a0908; }

a {
  text-decoration: none; }

button {
  border: none;
  background: none;
  cursor: pointer; }

p {
  margin: 5px 0; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

h1, h2, h3, h4, h5, h6 {
  text-align: center;
  font-weight: normal;
  margin: 0;
  padding: 0; }

main {
  margin: auto; }

span {
  display: block; }

.main-content {
  background: #f2f4f3; }
  .main-content-container {
    max-width: 70rem;
    margin: auto;
    padding: 3rem 5rem 0; }
  .main-content-section {
    padding-bottom: 5rem; }
  .main-content-title {
    font-size: 40px;
    margin-bottom: 30px;
    color: #6f1a07; }
  .main-content-subtitle {
    max-width: 40rem;
    margin: 0 auto 30px;
    font-size: 18px; }
  .main-content .tab-name {
    text-align: left;
    margin-bottom: 30px; }
  .main-content .list {
    display: grid;
    flex-direction: column;
    justify-items: center;
    grid-template-columns: 1fr 1fr; }
    .main-content .list-item {
      display: block;
      width: 90%;
      margin: auto;
      border-bottom: #0a0908 solid 1px;
      margin-bottom: 20px;
      height: 100%; }
  @media screen and (max-width: 768px) {
    .main-content-container {
      padding: 3rem 2rem 5rem; } }
  @media screen and (max-width: 550px) {
    .main-content {
      font-size: 15px; }
      .main-content-container {
        padding: 2rem 1rem 0; }
      .main-content-title {
        font-size: 30px; }
      .main-content-subtitle {
        font-size: 15px; }
      .main-content .tab-name {
        margin-bottom: 20px; }
      .main-content .list {
        display: block; }
        .main-content .list-item {
          width: 100%; } }

