.teaser {
  position: relative;

  &__in-grid {
    max-width: 70rem;
    margin: auto;
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }

  &__item {
    padding: 60px 60px 0;
  }

  &__image {
    display: flex;
    width: 100%;
  }

  .teaser-content {
    &__headline {
      color: #6f1a07;
      font-size: 40px;
      font-weight: 800;
      letter-spacing: 0px;
      line-height: 50px;
      text-align: left;
    }
  
    &__text {
      font-size: 15px;
      font-weight: normal;
      letter-spacing: 0px;
      line-height: 24px;
    }
  }


  &-center {
    width: calc(100vw - 9px);
    height: 70vh;
    margin-left: -50vw;
    margin-top: -5rem;
    left: 50%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url(/assets/images/restaurant/asiatisches-restaurant-berlin.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
    position: relative;
    display: block;
    text-align: center;
    letter-spacing: 0px;
    color:  #f2f4f3;

    &-content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-width: 50rem; 
      left: 0;
      right: 0;
      margin: auto;

      &__headline {
        font-weight: 700;
        line-height: 70px;
        margin: 40px 0;
        font-size: 55px;
      }

      &__text {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 550px) {
    &__in-grid {
      grid-template-columns: 1fr;
      padding: 20px 0;
      text-align: center;
    }

    &__item {
      padding: 0 20px;
    }

    .teaser-content {
      &__headline {
        font-size: 25px;
        text-align: center;
      }
    
      &__text {
        font-size: 13px;
        line-height: 40px;
      }
    }

    &-center {
      width: 100vw;
      height: 60vh;
  
      &-content {
        &__headline {
          line-height: 40px;
          margin: 20px 0;
          font-size: 25px;
        }
  
        &__text {
          font-size: 15px;
        }
      }
    }
  }
}
