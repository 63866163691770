.main-header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  height: 50vh;
  background-color: #0a0908;
  color: #f2f4f3;
  line-height: 1;

  &__wrapper {
    max-width: 70em;
    margin: auto;
  }

  &__text {
    display: flex;
    flex-direction: row;
    padding: 3rem 5rem;

    &-wrapper {
      width: 50%;
    }

    .header-title {
      font-size: 15px;
      text-align: left;
    }

    .header-subtitle {
      text-align: right;
    }

    .text-highlighted {
      font-weight: 600;
      font-size: 20px;
    }

  }

  &__logo {
    width: 100%;
    height: 25vh;
    position: absolute;
    top: 40%;
    transform: translateY(-40%);
    left: 0;

    &-url {
      display: contents;
    }

    .logo {
      width: 25vh;
      margin: auto;
      display: block;
    }
  }

  &__navigation {
    width: 60%;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
  }

  &__navigation-item {
    width: 10%;
    padding: 0 16px;
    text-align: center;
    color: #f2f4f3;

    &:hover,
    &:focus {
      color: #bbb;
    }
    
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }

  &__toggle-button {
    display: none;

  }

  @media screen and (max-width: 768px) {
    &__text {
      padding: 3rem 3rem;
    }

    &__navigation-item {
      width: 10%;
      padding: 0 10px;
      font-size: 13px;
    }
  }

  @media screen and (max-width: 550px) {
    &__text {
      padding: 2rem 1.5rem;
    }

    &__text {
      font-size: 15px;
    }

    .header-title {
      font-size: 13px;
    }

    .text-highlighted {
      font-size: 15px;
    }

    &__logo {
      width: 100%;
      height: 15vh;
      top: 42%;
      transform: translateY(-40%);

      .logo {
        width: 160px;
      }
    }

    &__navigation {
      width: 100%;
    }
  }
}
  

  
  