.policy {
  &__section {
    margin-bottom: 40px;

    &-name {
      margin-bottom: 20px;
      font-size: 20px;
      color: #6f1a07;
    }

    &-para {
      margin-bottom: 20px;
      font-size: 13px;
    }
  }
}