.menu {
  &__section {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }

    &-name {
      width: 100%;
      background-color: #5e503f;
      text-align: center;
      color: #f2f4f3;
      line-height: 1.5;
    }

    &-image {
      width: 100%;
      height: 15rem;
      object-fit: cover;
    }

    &-note {
      width: 70%;
      margin: 15px auto 30px;
      font-style: italic;
      text-align: center;
    }

    &-list {
      display: grid;
      flex-direction: column;
      grid-template-columns: 1fr 1fr;

      &__item {
        margin-bottom: 10px;

        &:nth-child(odd) {
          margin-right: 40px;
        }
  
        &:nth-child(even) {
          margin-left: 40px;
        }

        .item-name {
          display: block;
          width: 80%;
          float:left
        }

        .item-title {
          color: #6f1a07;
        }

        .item-info {
          font-size: 13px;
        }

        // .item-price {
        //   text-align: right;
        // }
      }
    }
  }

  @media screen and (max-width: 550px) {
    &__section {
      &-note {
        width: 90%;
      }

      &-list {
        grid-template-columns: 1fr;

        &__item {
          margin-bottom: 10px;
  
          &:nth-child(odd) {
            margin-right: 0;
          }
    
          &:nth-child(even) {
            margin-left: 0;
          }
        }
      }
    }
  }
}