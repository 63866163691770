@import './toolbar.scss';
@import url('https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css');

figure {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 10px;
	margin-inline-end: 0;
}

body {
  margin: 0;
}