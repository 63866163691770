.gallery {
  &-list {
    display: grid;
    flex-direction: column;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    &__item {
      position: relative;
      max-width: 250px;
      max-height: 250px;
      margin: 10px;
      overflow: hidden;
      

      &-image {
        width: 100%;
        height: 100%;
      }

      .image-enlarged {
        background: rgba(0,0,0,.5) no-repeat center;
        width: 100%;
        height: 100%;
        position: fixed;
        z-Index: 10000;
        top: 0;
        left: 0;
        // cursor: zoom-out;
      } 
    }
  }

  @media screen and (max-width: 768px) {
    &-list {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media screen and (max-width: 550px) {
    &-list {
      grid-template-columns: 1fr;

      &__item {
        margin: 10px 0;
      }
    }
  }
}