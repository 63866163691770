.order {
  position: relative;

  &-list {
    display: grid;
    flex-direction: column;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;

    &__option {
      border: #5e503f 3px solid;
      border-radius: 5px;
      max-width: 25rem;
      background-color: #d1baa7;
      margin: 0 10px 20px;
      padding: 20px 30px 30px;
      text-align: center;

      .option-title {
        display: block;
        margin: 0 auto 10px;
      }

      .option-subtitle {
        margin-bottom: 20px;
      }

      .option-logo {
        max-width: 200px;
        width: 100%;
        display: block;
        margin: auto;
      }

      .option-text {
        margin: 20px 0;
        
      }

      .option-button {
        max-width: 200px;
        background-color: #5e503f;
        border-radius: 5px;
        margin: auto;

        &:hover,
        &:active,
        &:focus {
          background-color: #785b38;
        }

        &-wrapper {
          display: block;
          padding: 10px;
          color: #f2f4f3;
          font-weight: bold;

        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    &-list {
      grid-template-columns: 1fr;

      &__option {
        padding: 20px 20px 30px;
        text-align: center;
      }
    }
  }
}