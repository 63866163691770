.footer {
  background-color: #0a0908;
  color: #f2f4f3;

  &__wrapper {
    padding: 32px;
    max-width: 50rem;
    margin: auto;
  }

  &__items {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
  }

  &__column:last-child {
    text-align: right;
  }


  &__column-name {
    font-weight: 800;
  }

  &__logo {
    width: 100px;
    margin-bottom: 16px;

  }
  &__text {
    color: #f2f4f3;
  }

  &__information {
    margin-top: 16px;
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
  }

  &__data {
    font-weight: 800;
    color: #f2f4f3;

    &:hover,
    &:focus {
      color: #bbb;
    }
  }

  &__media {
    width: 25px;
    margin: 0 0 0 20px;
  }

  @media screen and (max-width: 550px) {
    font-size: 15px;

    &__items {
      display: block
    }

    &__column,
    &__column:last-child {
      text-align: center;
      margin-bottom: 20px;
    }

    &__information {
      grid-template-columns: 1fr;
    }

    &__media {
      width: 25px;
      margin: 0 10px;
    }
  }
}